
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import UserManagementService from "@/services/user-management";
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import PopUp from "@/components/PopUp.vue";
import FilterComponent from "@/components/sidebar-employee-filter.vue";
import FilterService from "@/services/filter-service";
import utils from "@/utils/utils";

@Component({
  components: { Header, Loader, PopUp, FilterComponent },
})
export default class UserManagement extends Vue {
  public currentPage = 1;
  loading = true;
  public ModuleObject: any = {};
  saveData = true;
  error: any = "";
  // For Modal
  modalShow = false;
  modalMessage: string[] = [];
  modalSimpleMessage: string = "";
  modalType = "";
  closeModal() {
    this.modalShow = false;
    this.modalMessage = [];
    this.modalType = "";
    this.modalSimpleMessage = ""
  }
  showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
    this.modalShow = true;
    this.modalMessage = array;
    this.modalType = type;
    this.modalSimpleMessage = message
  }
  // modal end
  public module_options: any = [];
  retrieve() {
    this.loading = true;
    UserManagementService.get_screen_modules()
      .then((res) => {
        this.module_options = res.data.module;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }
  get rows() {
    return this.module_options.length;
  }
  paginatedItems() {
    const start = (this.currentPage - 1) * 20;
    const end = start + 20;
    return this.module_options.slice(start, end);
  }
  mounted() {
    this.retrieve();
  }
  saveModule() {
    const keyNameMapping = {
      api_route: 'Module',
    };
    this.error = ""
    const error_list = utils.getEmptyKeys(this.ModuleObject, ['api_route'])
    if (error_list.length > 0) {
      this.error = utils.generateErrorMessageFields(error_list, keyNameMapping)
    }
    else {
      const moduleObject: any = {
        api_route: this.ModuleObject.api_route
      }
      this.loading = true;
      UserManagementService.post_screen_modules(moduleObject)
        .then((res) => {
          this.showModal("Created Successfully", [], "success")
          this.resetModule();
          this.retrieve();
          this.refreshFilters();
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log('Unexpected error:', e);
          this.showModal('An unexpected error occurred', [], "error");
        });
    }
  }
  editModuleToggle(data: any) {
    this.saveData = false;
    this.ModuleObject = data;
    this.$root.$emit("bv::toggle::collapse", "sidebar-variant-create-module")
  }
  editModule() {
    this.error = ""
    const error_list = utils.getEmptyKeys(this.ModuleObject, ['api_route'])
    if (error_list.length > 0) {
      this.error = utils.generateErrorMessage(error_list)
    }
    else {
      this.loading = true;
      UserManagementService.update_module(this.ModuleObject.id, this.ModuleObject)
        .then((res) => {
          this.resetModule();
          this.retrieve();
          this.refreshFilters();
          this.showModal("Updated Successfully", [], "success");
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log('Unexpected error:', e);
          this.showModal('An unexpected error occurred', [], "error");
        });
    }
  }
  deleteModule(item: any) {
    this.loading = true;
    UserManagementService.delete_module(item.id)
      .then((res) => {
        this.retrieve();
        this.refreshFilters();
        this.showModal("Deleted Successfully", [], "success");
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log('Unexpected error:', e);
        this.showModal('An unexpected error occurred', [], "error");
      });
  }
  resetModule() {
    this.error = "";
    this.saveData = true;
    this.ModuleObject = {
      api_route: '',
    }
    for (let field of this.filter_fields) {
      field.value = "";
    }
  }
  filter_fields = [
    { type: "text", label: "Module", name: "module", value: "", param_name: "api_route", filter_type: "simple", },
  ];
  handleFilterChange(element: any) {
    if (element.filter_type === "simple") {
      interface SimpleObj {
        [key: string]: any;
      }
      const simpleFilteredObj: SimpleObj = {};
      this.filter_fields.forEach((filter) => {
        simpleFilteredObj[filter.param_name] = filter.value;
      });
      this.loading = true;
      FilterService.get_modules(simpleFilteredObj).then((data) => {
        if (
          data.data.module !== undefined &&
          data.data.module !== null
        ) {
          this.module_options = data.data.module;
          this.loading = false;
        } else {
          this.retrieve();
        }
      });
    }
  }
  refreshFilters() {
    this.resetModule();
  }
}
